body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-xs: 12px;
  --font-s: 16px;
  --font-m: 24px;
  --font-l: 32px;
  --font-xl: 48px;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-primary: #3b7634;
  --color-secondary: #e2eae1;
  --color-success: #3fb57e;
  --color-danger: #FF004D;
  --color-warning: #FAEF5D;
  --color-info: #687EFF;
  --color-light: #f0f0f0;
  --color-font: #252525;
  --color-background: #ebebeb;
  --color-white-modal: #ffffff99;
  --shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  --border-radius-s: 5px;
  --border-radius-m: 10px;
  --border-radius-l: 20px;
  --border-radius-all: 50%;
}

.space-select {
  width: calc(100% - 10px);
}

.space-select-two {
  width: 50%;
}

@media screen and (max-width: 425px) {
  .space-select-two {
    width: 100%;
    margin-bottom: 10px;
  }
}